import { MutationType } from "@/store/mutations/type";
import { IStoreType } from "@/interfaces/IStoreType";
import { IPlayer } from "@/interfaces/IPlayer";
import { ICurrency } from "@/interfaces/ICurrency";

export default {
  [MutationType.SET_STREAMING]: (state: IStoreType, val: boolean): void => {
    state.videoStreaming = val;
  },
  [MutationType.SET_STREAMING_RELOAD]: (
    state: IStoreType,
    val: boolean
  ): void => {
    state.videoStreamingReload = val;
  },
  [MutationType.SET_STREAMING_PAUSE]: (
    state: IStoreType,
    val: string
  ): void => {
    state.videoStreamingPause = val;
  },
  [MutationType.SET_VOLUME]: (state: IStoreType, val: number): void => {
    state.volume = val;
  },
  [MutationType.SET_STREAMING_ERROR]: (
    state: IStoreType,
    val: boolean
  ): void => {
    state.videoStreamingError = val;
  },
  [MutationType.SET_STREAMING_LOADING]: (
    state: IStoreType,
    val: boolean
  ): void => {
    state.videoStreamingLoading = val;
  },
  [MutationType.SET_PLAYER]: (state: IStoreType, val: IPlayer): void => {
    state.player = val;
  },
  [MutationType.SET_CURRENCY]: (state: IStoreType, val: ICurrency): void => {
    state.currency = val;
  },
  [MutationType.REST_BALANCE]: (state: IStoreType, val: number): void => {
    if (!state.player) return;
    state.player.lastBalance -= val;
  },
  [MutationType.ADD_BALANCE]: (state: IStoreType, val: number): void => {
    if (!state.player) return;
    state.player.lastBalance += val;
  },
  [MutationType.SET_BALANCE]: (state: IStoreType, val: number): void => {
    if(state.player)
      state.player.lastBalance = val;
  },
  [MutationType.SET_AGENT_ONLY]: (state: IStoreType, val: boolean): void => {
    state.isAgentOnly = val;
  },
};
