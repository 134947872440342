import { io, Socket } from "socket.io-client";
import { ServerToClientEvents } from "@/interfaces/ServerToClientEvents";
import { ClientToServerEvents } from "@/interfaces/ClientToServerEvents";
import { IParamsSocket } from "@/interfaces/IParamsSocket";

export class SocketIo {
  static instance: Socket<ServerToClientEvents, ClientToServerEvents> | null =
    null;
  static url: string = process.env.VUE_APP_SOCKET || "";
  static get_Instance(
    params: IParamsSocket
  ): Socket<ServerToClientEvents, ClientToServerEvents> {
    if (!params || !params.gameUuid || !params.userId) {
      console.log("Error connectng to websocket, rouletteId is required");
    }
    if (!this.instance) {
      this.instance = io(this.url, {
        transports: ["websocket"],
        query: {
          gameUuid: params.gameUuid,
          userId: params.userId,
          operator: params.operator,
          providerId: params.providerId
        },
      }); //
    }
    return this.instance;
  }

  static close(): void {
    if (this.instance) {
      this.instance.io.engine.removeAllListeners();
      this.instance.io._close();
      this.instance = null;
      //console.log("Borrar socket",this.instance);
    }
  }
}
