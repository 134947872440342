import { GettersType } from "@/store/getters/type";
import { IStoreType } from "@/interfaces/IStoreType";
import { IPlayer } from "@/interfaces/IPlayer";
import { ICurrency } from "@/interfaces/ICurrency";

export default {
  [GettersType.IS_MUTE]: (state: IStoreType): boolean => state.mute,
  [GettersType.GET_STREAMING]: (state: IStoreType): boolean =>
    state.videoStreaming,
  [GettersType.GET_STREAMING_LOADING]: (state: IStoreType): boolean =>
    state.videoStreamingLoading,
  [GettersType.GET_STREAMING_ERROR]: (state: IStoreType): boolean =>
    state.videoStreamingError,
  [GettersType.GET_STREAMING_PAUSE]: (state: IStoreType): string =>
    state.videoStreamingPause,
  [GettersType.GET_VOLUME]: (state: IStoreType): number => state.volume,
  [GettersType.GET_PLAYER]: (state: IStoreType): IPlayer | null => state.player,
  [GettersType.GET_CURRENCY]: (state: IStoreType): ICurrency | null =>
    state.currency,
  [GettersType.GET_BALANCE]: (state: IStoreType): number | undefined =>
    state.player?.lastBalance,
  [GettersType.IS_AGENT_ONLY]: (state: IStoreType): boolean => state.isAgentOnly
};
